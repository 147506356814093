<template>
  <div>
    <h2 class="mb-4">BelegPositionen Import</h2>

    <div class="mb-4">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="insertData"
      >
        BelegPositionen importieren
      </button>
    </div>

    <div class="my-4">
      <label>
        Sortiert nach Menge:
        <input type="checkbox" name="sort" id="sort" v-model="sort" />
      </label>
      <div class="flex gap-4">
        <label>
          Filter:
          <select name="filter" id="filter">
            <option value="artikelcode">Artikelnr.</option>
          </select>
        </label>
        <label>
          Artikelnr.
          <input
            type="text"
            name="artikelcode"
            id="artikelcode"
            v-model="filter_artikelcode"
          />
        </label>
        <label>
          Bezeichnung
          <input
            type="text"
            name="bezeichnung"
            id="bezeichnung"
            v-model="filter_bezeichnung"
          />
        </label>
        <label>
          Liefertermin von
          <input
            type="date"
            name="liefertermin_von"
            id="liefertermin_von"
            v-model="date_from"
          />
        </label>
        <label>
          Liefertermin bis
          <input
            type="date"
            name="liefertermin_bis"
            id="liefertermin_bis"
            v-model="date_to"
          />
        </label>
      </div>
    </div>

    <div>
      <dashboard-element>
        <dashboard-head title="Beleg Positionen" link="/auswertungen" />
        <dashboard-table class="p-3">
          <dashboard-card
            v-for="beleg_position in sort
              ? belege_sortiert
              : nachArtikelGefiltert"
            :key="beleg_position.belegnr"
            :title="beleg_position.bezeichnung"
            :content="
              `${beleg_position.artikelcode} ${
                sort ? beleg_position.menge + ' Stück' : ''
              }`
            "
            :link="`/artikel/${beleg_position.artikelcode}`"
          />
        </dashboard-table>
      </dashboard-element>
    </div>

    <div>
      <h3 class="font-bold text-xl">{{ filtered.bezeichnung }}</h3>
      <p>Menge gefertigt: {{ filtered.menge_gesamt }}</p>
      <p>Umsatz netto: {{ filtered.umsatz_netto }}</p>
      <p>Korn: {{ filtered.koernung }}</p>
      <p>Karat: {{ filtered.karat }}</p>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "@/components/dashboard/DashboardElement.vue";
import DashboardHead from "@/components/dashboard/DashboardHead.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import { supabase } from "../../plugins/supabase";

export const GET_MY_DATA = gql`
  query belege_positionen {
    belege_positionen(
      where: {
        Position: { _neq: "" }
        LieferterminDatum: { _gte: "2020-01-01" }
      }
    ) {
      Belegart
      BelegNr
      Position
      Menge
      Artikelcode
      Bezeichnung
      Preis
      Gesamt
      LieferterminDatum
      Durchmesser
      FormCode
      Winkel
      Belagbreite
      Belagh_he
      Gesamth_he
      Bohrung
      K_rnung
      Konzentration
      Bindung
      Pressvolumen
      Karat
      Korngewicht
      Bindungsgewicht
      K_rperpreis
      Kornpreis
      Rohling
      Rohlingh_he
    }
  }
`;

const reducer = (previousValue, currentValue) => previousValue + currentValue;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  data: () => {
    return {
      date_from: "",
      date_to: "",
      filter_artikelcode: "",
      filter_bezeichnung: "",
      sort: false
    };
  },
  apollo: {
    belege_positionen: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  },
  computed: {
    belegepositionen() {
      return this.$store.getters.getAllBelegPositionen;
    },
    belege_ab() {
      return this.$store.getters.getBelegeOnlyAB;
    },
    belege_2020() {
      return this.$store.getters.getBelegeFrom2020;
    },
    belege_2021() {
      return this.$store.getters.getBelegeFrom2021;
    },
    belege_sortiert() {
      return this.sortArrayOfObjects(
        this.sumUpArrayByProperty(this.belege_2021),
        "menge"
      );
    },
    nachArtikelGefiltert() {
      return this.$store.getters.getBelegeBezeichnung(this.filter_bezeichnung);
    },
    filtered() {
      const menge = this.nachArtikelGefiltert.map(item => item.menge);
      const gesamt = this.nachArtikelGefiltert.map(item => item.gesamt);
      const karat = this.nachArtikelGefiltert.map(item => item.karat);

      return {
        bezeichnung: this.nachArtikelGefiltert[0].bezeichnung,
        menge_gesamt: menge.reduce(reducer) + " Stück",
        umsatz_netto: new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR"
        }).format(gesamt.reduce(reducer)),
        koernung: this.nachArtikelGefiltert[0].koernung,
        karat: karat.reduce(reducer) * menge.reduce(reducer) + " ct"
      };
    }
  },
  methods: {
    sortArrayOfObjects: (arr, key) => {
      return arr.sort((a, b) => {
        return b[key] - a[key];
      });
    },
    sumUpArrayByProperty: arr => {
      const output = arr.reduce((accumulator, cur) => {
        const found = accumulator.find(elem => {
          return elem.artikelcode == cur.artikelcode;
        });

        if (found) {
          found.menge += cur.menge;
        } else {
          accumulator.push(cur);
        }

        return accumulator;
      }, []);

      return output;
    },
    parseNumber(str) {
      return parseFloat(str.replace(",", "."));
    },
    async insertData() {
      const neue_belegpositionen = [];
      this.belege_positionen.map(bp =>
        neue_belegpositionen.push({
          belegart: bp.Belegart,
          belegnr: `${bp.Belegart} ${bp.BelegNr}'${bp.Position}`,
          position: bp.Position,
          menge: parseInt(bp.Menge),
          artikelcode: bp.Artikelcode,
          bezeichnung: bp.Bezeichnung,
          preis: parseFloat(bp.Preis),
          gesamt: parseFloat(bp.Gesamt),
          liefertermindatum: bp.Liefertermindatum,
          durchmesser: parseFloat(bp.Durchmesser),
          form: bp.Formcode,
          winkel: parseFloat(bp.Winkel),
          belagbreite: parseFloat(bp.Belagbreite),
          belaghoehe: parseFloat(bp.Belagh_he),
          gesamthoehe: parseFloat(bp.Gesamth_he),
          bohrung: bp.Bohrung,
          koernung: bp.K_rnung,
          konzentration: bp.Konzentration,
          bindung: bp.Bindung,
          pressvolumen: parseFloat(bp.Pressvolumen),
          karat: parseFloat(bp.Karat),
          korngewicht: parseFloat(bp.Korngewicht),
          bindungsgewicht: parseFloat(bp.Bindungsgesicht),
          koerperpreis: parseFloat(bp.K_rperpreis),
          kornpreis: parseFloat(bp.Kornpreis),
          rohling: bp.Rohling,
          rohlingshoehe: parseFloat(bp.Rohlingh_he)
        })
      );

      await supabase.from("belegpositionen").insert(neue_belegpositionen);
    }
  }
};
</script>
